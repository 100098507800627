<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentTime /> 
</template>

<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/worker/dashboard/HeaderDash.vue'
import SideMenu from '@/components/worker/dashboard/SideMenu.vue'
import ContentTime from '@/components/worker/dashboard/modules/report/time/ContentTime.vue'

export default 
{
  name: 'Time',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentTime
  }
}
</script>

<style>


</style>